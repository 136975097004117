import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`1. DISCLAIMER`}</h2>
    <br />
    <p>{`Always make independent assessments based on your personal observations of the terrain.`}</p>
    <br />
    <p>{`Use the information in the app as a guide and follow the advice the app offers along
with relevant information from other sources.`}</p>
    <br />
    <p>{`Always assess the risk in exposed areas to choose where, when, and how you move.`}</p>
    <br />
    <p>{`The information in the app is not always updated and there may be errors and omissions.
The information provided in the app is often based on regional information and does not
describe conditions exactly where you are.`}</p>
    <br />
    <p>{`Information about weather and other natural conditions are based on available
observations and weather forecasts. Weather conditions change quickly and can therefore
deviate from forecasts and reported observations.`}</p>
    <br />
    <p>{`Rapid changes in weather conditions can mean that route descriptions do not always match
the conditions at the time you are traveling in the mountains.`}</p>
    <br />
    <p>{`Remember that terrain traps and other local conditions can rarely be accurately
described and that you must always make a specific assessment of local conditions.`}</p>
    <br />
    <p>{`Consult others who have recently visited the area you are exploring.`}</p>
    <br />
    <p>{`By using the app, you accept the reservations concerning the accuracy of the information
and the limitation of liability of liability for the provider of the app.`}</p>
    <br />
    <h2>{`2. LIMITATION OF LIABILITY`}</h2>
    <br />
    <p>{`The app provider and individual contributors are not liable for any consequences
resulting from the use of the information provided.`}</p>
    <br />
    <p>{`The use of the app is governed by Norwegian law. To the maximum extent permissible by
law the provider of the app and expressly disclaims all warranties for the accuracy of
the information provided in the app. Neither the app provider nor any contributors shall
be held accountable for any direct, special, incidental, consequential or other damages
whatsoever, (including, without limitation, damages for loss of business information, or
other pecuniary loss) resulting from the use or inability to use the information in the
app.`}</p>
    <br />
    <h2>{`5. REGISTRATION OF USAGE DATA`}</h2>
    <br />
    <h2>{`6. FINAL PROVISIONS`}</h2>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      